.sheettable {
    border-collapse: collapse;
    width: 100%;
    text-align: center;

  }


  /* @media print {
    table {
        page-break-before: always;
        page-break-inside: avoid;
        page-break-after: always;

    }

    tr{
    }
} */
  
.sheettable, .sheettable tr, .sheettable td, .sheettable th {
    border: 1px solid black;
  }
  
  .sheettable th {
    vertical-align: center;
  }
  
  .sheettable td:empty:after {
    content: "\00a0"; /* HTML entity of &nbsp; */
  }

  