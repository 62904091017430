.styled-table-container {
        padding: 15px;
  }

  .styled-table-container h2 {
    font-size: 20px;
    color: #333;
    margin-bottom: 10px;
  }

  .google-visualization-table-table {
    font-family: 'Arial', sans-serif;
    border-spacing: 0;
    border-collapse: collapse;
    width: 100%;
  }

  .google-visualization-table-table th,
  .google-visualization-table-table td {
    border: 1px solid #ddd;
    padding: 6px;
    text-align: left;
  }

  .google-visualization-table-th-fullwidth {
    background-color: #ffffff;
    font-weight: bold;
    text-align: center !important;
    colspan: 3;
  }
  .google-visualization-table-tr-head{
    line-height: 1.1 !important;
  }

  .google-visualization-table-tr-even {
    background-color: #ffffff;
    line-height: 1.1 !important;

  }

  .google-visualization-table-tr-odd {
    background-color: #fafafa;
    line-height: 1.1 !important;
  }

  .google-visualization-table-tr-over:hover {
    background-color: #f7f7f7;
  }

